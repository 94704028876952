<template>
  <div
    class="flex-column mt-1 address-detail address-edit-container"
    v-if="internalValue"
  >
    <div class="address-title mt-3 mb-4 px-2 px-sm-0">
      <h1 v-html="titleSection"></h1>
    </div>

    <div v-if="showAutomplete">
      <v-form
        v-on:submit.prevent
        ref="form"
        class="add-address-form"
        v-model="valid"
        :lazy-validation="lazy"
      >
        <!-- v-if="!validateAddress" -->
        <div class="d-flex px-2 px-sm-0">
          <vue-google-autocomplete
            id="map"
            classname="new-delivery-address"
            ref="address"
            placeholder="Scrivi l'indirizzo di consegna"
            v-on:placechanged="getAddressData"
            :enable-geolocation="true"
            country="it"
            v-if="googleEnabled"
          >
          </vue-google-autocomplete>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <!-- type="submit" -->
              <v-btn
                class="login-button"
                large
                depressed
                icon
                @click="geolocate"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-map-marker</v-icon></v-btn
              >
            </template>
            {{ $t("profile.addresses.geolocalize") }}
          </v-tooltip>
        </div>
        <span
          ><label class="ml-3 ml-sm-1 mt-1 d-inline-block grey--text" for="map">
            Es.: Via Verdi, 5, Milano, MI, Italia</label
          ></span
        >

        <v-alert border="left" type="error" v-if="error">{{
          $t(error)
        }}</v-alert>
      </v-form>

      <div
        class="my-4 missing-address-data font-weight-bold"
        v-if="missingAddressData"
      >
        {{ missingAddressDataMessage }}
      </div>

      <!-- v-if="!validateAddress" -->
      <v-row class="address-actions mt-3">
        <v-col cols="12" sm="6" class="d-flex justify-start">
          <v-btn
            v-if="showBackButton"
            color="primary"
            outined
            large
            depressed
            outlined
            @click="$router.go(-1)"
          >
            {{ $t("profile.addresses.back") }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex justify-end">
          <v-btn
            color="primary"
            class="primary-button"
            :disabled="checkAddressDisabled"
            @click="checkAddress"
            large
            depressed
          >
            {{ $t("profile.addresses.verify") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="!showAutomplete">
      <span class="d-block my-3 text-body-1">{{
        $t("profile.addresses.data")
      }}</span>
      <v-row>
        <v-col cols="12" sm="8"
          ><v-text-field
            :label="$t('profile.addresses.city')"
            v-model="internalValue.city"
            readonly
            outlined
            filled
            dense
          >
          </v-text-field
        ></v-col>
        <v-col cols="6" sm="2">
          <v-text-field
            :label="$t('profile.addresses.province')"
            v-model="internalValue.province"
            readonly
            outlined
            filled
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="2">
          <v-text-field
            :label="$t('profile.addresses.postalCode')"
            v-model="internalValue.postalcode"
            readonly
            outlined
            filled
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="9">
          <v-text-field
            :label="$t('profile.addresses.address')"
            v-model="internalValue.address1"
            filled
            readonly
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            :label="$t('profile.addresses.addressNumber')"
            v-model="internalValue.addressNumber"
            :filled="isFilled"
            :readonly="isReadOnly"
            outlined
            dense
          >
          </v-text-field>
        </v-col>
      </v-row>
    </div>

    <div v-if="homeDeliveryAvailable === false">
      <v-alert type="error">
        {{ $t("profile.addresses.deliveryNo") }}
      </v-alert>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center justify-sm-end mb-6 mb-sm-0"
        >
          <v-btn
            color="primary"
            class="primary-button"
            @click="addAnotherAddress"
            large
            depressed
          >
            {{ $t("profile.addresses.addAnotherAddress") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="homeDeliveryAvailable === true" class="mt-1">
      <v-alert type="success">
        {{ $t("profile.addresses.deliveryYes") }}
      </v-alert>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center justify-sm-end mb-6 mb-sm-0"
        >
          <v-btn
            color="primary"
            class="primary-button"
            @click="addAnotherAddress"
            large
            depressed
          >
            {{ $t("profile.addresses.modifyAddress") }}
          </v-btn>
        </v-col>
      </v-row>
      <span class="d-block my-3 text-body-1">{{
        $t("profile.addresses.complete")
      }}</span>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="$t('profile.addresses.type')"
            :placeholder="$t('profile.addresses.typePlaceholder')"
            v-model="internalValue.addressName"
            :rules="[requiredRule()]"
            outlined
            required
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="$t('profile.addresses.name')"
            :placeholder="$t('profile.addresses.namePlaceholder')"
            v-model="internalValue.doorbellName"
            :rules="[requiredRule()]"
            outlined
            required
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="3">
          <!-- Piano -->
          <v-select
            :items="floorItems"
            v-model="internalValue.floor"
            :label="$t('profile.addresses.floor')"
            :rules="[requiredRule('Piano obbligatorio')]"
            dense
            outlined
          ></v-select>
        </v-col>

        <v-col cols="6" sm="3">
          <v-switch
            v-model="internalValue.elevator"
            :true-value="true"
            :false-value="false"
            inset
            :label="$t('profile.addresses.elevator')"
            dense
            class="mt-0"
            hide-details
          ></v-switch>
        </v-col>

        <v-col cols="6" sm="3">
          <!-- scala -->
          <v-text-field
            :label="$t('profile.addresses.doorbellNumber')"
            :placeholder="$t('profile.addresses.doorbellNumberPlaceholder')"
            v-model="internalValue.doorbellNumber"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="6" sm="3">
          <v-text-field
            :label="$t('profile.addresses.phone')"
            :placeholder="$t('profile.addresses.phonePlaceholder')"
            v-model="internalValue.referenceMobileNumber"
            :rules="isPhoneNumberRules"
            v-mask="'##########'"
            required
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12">
          <!-- Altre info, addressNote -->
          <v-text-field
            :label="$t('profile.addresses.other')"
            :placeholder="$t('profile.addresses.otherPlaceholder')"
            v-model="internalValue.addressNote"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="12">
          <p>{{ $t("profile.requiredFields") }}</p>
        </v-col>

        <v-row class="address-actions mx-1">
          <v-col cols="12" sm="6" class="d-flex justify-start mb-4">
            <v-btn
              outlined
              color="primary"
              large
              depressed
              :to="{ name: 'Addresses' }"
            >
              {{ $t("profile.goBack") }}
            </v-btn>
          </v-col>

          <v-col cols="12" sm="6" class="d-flex justify-end mb-4">
            <v-btn
              color="primary"
              class="primary-button"
              @click="saveAddress"
              large
              depressed
              :disabled="!checkRequiredValues"
            >
              {{ $t("profile.addresses.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </div>
</template>
<style lang="scss">
.address-edit-container {
  .section-title {
    font-size: 20px;
  }
  .add-address-form {
    #map {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.42);
      border-radius: 5px;
      padding: 6px;
    }
    .new-delivery-address {
      background: #ffffff;
    }
  }
  .missing-address-data {
    color: red;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .address-actions {
      .v-btn {
        width: 100%;
      }
    }
  }
  .v-autocomplete {
    .v-input__append-inner {
      display: none;
    }
  }
  .v-input--switch {
    .v-input__slot {
      background-color: transparent !important;
    }
  }
  .mandatory {
    font-size: 14px;
  }
}
.visibility-visible {
  visibility: visible !important;
}
.visibility-hidden {
  visibility: hidden !important;
}
</style>
<script>
import GeoService from "~/service/geoService";
import DeliveryService from "~/service/deliveryService";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import gmapsInit from "@/components/buttons/gmaps";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import { requiredValue, isGenericPhone } from "~/validator/validationRules";
var google;

export default {
  name: "AddressEdit",
  components: { VueGoogleAutocomplete },
  props: {
    showBackButton: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      valid: false,
      // validRequiredValues: false,
      lazy: true,
      error: null,
      showAutomplete: true,
      homeDeliveryAvailable: null,
      googleEnabled: false,
      checkAddressDisabled: true,
      isReadOnly: true,
      isFilled: true,
      internalValue: {
        addressName: null,
        doorbellName: null,
        city: null,
        addressNote: null,
        postalcode: null,
        province: null,
        addressNumber: null,
        address1: null,
        floor: null,
        referenceMobileNumber: null,
        elevator: null,
        longitude: null,
        latitude: null,
        country: null,
        apartmentNumber: null,
        doorbellNumber: null
      },
      address: null,
      provinceList: [],
      cityList: [],
      addressList: [],
      postalcodeList: [],
      requiredRule: requiredValue,
      isGenericPhoneRules: [isGenericPhone()],
      isPhoneNumberRules: [
        requiredValue("Telefono obbligatorio"),
        isGenericPhone()
      ],
      addressGeoCoded: null,
      validateAddress: false,
      missingAddressData: false,
      missingAddressDataMessage: null,
      addressDataInfo: [
        { field: "city", message: "città", required: true },
        { field: "address1", message: "indirizzo", required: true },
        // { field: "postalcode", message: "CAP", required: true },
        { field: "province", message: "provincia", required: true },
        { field: "addressNumber", message: "numero civico", required: true }
      ],
      floorItems: [
        "Piano terra",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "Oltre il sesto"
      ],
      selectedProvince: null,
      selectedCity: null,
      selectedAddress: null,
      city: null,
      address1: null
    };
  },
  directives: { mask },
  computed: {
    checkRequiredValues() {
      return (
        this.internalValue.addressName &&
        this.internalValue.doorbellName &&
        this.internalValue.referenceMobileNumber &&
        this.internalValue.floor
      );
    },
    enableSave() {
      return !this.valid;
    },
    titleSection() {
      return this.internalValue.addressId
        ? this.$t("profile.addresses.editAddressTitle", [
            this.internalValue.addressName
          ])
        : this.$t("profile.addresses.newAddressTitle");
    }
  },
  methods: {
    ...mapActions({
      setAddress: "cart/setAddress"
    }),
    resetForm() {},
    getAddressData(addressData, place) {
      //check @home
      let vm = this;
      this.missingAddressData = false;

      //provincia
      place.address_components.forEach(element => {
        if (element.types[0] == "administrative_area_level_2") {
          vm.internalValue.province = element.short_name;
          return;
        }
        if (element.types[0] == "country") {
          vm.internalValue.country = { iso: element.short_name };
          return;
        }
      });

      //città
      place.address_components.forEach(element => {
        if (
          element.types[0] == "administrative_area_level_3" ||
          element.types[0] == "locality"
        )
          //add custom variable
          vm.internalValue.city = element.short_name;
        return;
      });

      // vm.internalValue.city =
      //   addressData.administrative_area_level_3 ||
      //   addressData.locality ||
      //   addressData.administrative_area_level_2;
      vm.internalValue.postalcode = addressData.postal_code;
      vm.internalValue.addressNumber = addressData.street_number;
      vm.internalValue.address1 = addressData.route;
      vm.internalValue.latitude = addressData.latitude;
      vm.internalValue.gpsLatitude = addressData.latitude;
      vm.internalValue.gpsLongitude = addressData.longitude;
      vm.internalValue.longitude = addressData.longitude;
      vm.checkAddressDisabled = false;
    },
    async checkAddress() {
      let vm = this;
      vm.missingAddressData = false;
      vm.missingAddressDataMessage =
        "L'indirizzo inserito è incompleto, mancano i seguenti campi: ";
      vm.addressDataInfo.forEach(element => {
        if (element.required) {
          if (vm.internalValue[element.field] == undefined) {
            vm.missingAddressDataMessage += element.message + ", ";
            vm.missingAddressData = true;
          }
        }
      });
      if (vm.missingAddressData) {
        vm.missingAddressDataMessage = vm.missingAddressDataMessage.slice(
          0,
          -2
        );
        vm.missingAddressDataMessage += ".";
      } else {
        try {
          vm.dataValidated = await DeliveryService.isValidAddress({
            country: vm.internalValue.country,
            longitude: vm.internalValue.longitude,
            gpsLongitude: vm.internalValue.longitude,
            latitude: vm.internalValue.latitude,
            gpsLatitude: vm.internalValue.latitude,
            address1: vm.internalValue.address1,
            city: vm.internalValue.city,
            postalcode: vm.internalValue.postalcode,
            province: vm.internalValue.province,
            addressNumber: vm.internalValue.addressNumber
          });
          if (vm.dataValidated.active == 1) {
            vm.homeDeliveryAvailable = true;
            vm.showAutomplete = false;
          } else {
            vm.homeDeliveryAvailable = false;
            vm.showAutomplete = false;
          }
          // if (vm.dataValidated.addressNumber) {
          //   vm.isFilled = true;
          //   vm.isReadOnly = true;
          // } else {
          //   vm.isFilled = false;
          //   vm.isReadOnly = false;
          // }
        } catch (err) {
          vm.homeDeliveryAvailable = false;
        }
        this.results = true;
      }
    },
    resetAddress() {
      this.selectedAddress = null;
      this.addressList = [];
      this.internalValue.address1 = null;
    },
    async getAddress(addressId) {
      this.internalValue = await DeliveryService.getAddresById(addressId);
    },
    async geocodeAddress(e) {
      let vm = this;
      e.preventDefault();
      if (vm.$refs.form.validate()) {
        let isGecoded = false;
        if (vm.internalValue.latitude && vm.internalValue.longitude) {
          isGecoded = true;
        } else {
          let address = await GeoService.geocodeAddress(vm.internalValue);
          if (address) {
            Object.assign(vm.internalValue, address);
            isGecoded = true;
          }
        }

        if (isGecoded) {
          vm.internalValue.city = vm.internalValue.city.toUpperCase();
          vm.internalValue.province = vm.internalValue.province.toUpperCase();
          //vm.internalValue = address;
          // vm.addressGeoCoded = Object.assign({}, address, vm.internalValue);
          // vm.addressGeoCoded.city = vm.addressGeoCoded.city.formatted_address;
          // vm.addressGeoCoded.address1 =
          //   vm.addressGeoCoded.address1.formatted_address;

          // //? valido l'indirizzo geocodificato
          vm.validateAndSaveAddress();
        } else {
          vm.error = "message.invalidAddress1";
        }
      }
    },
    addAnotherAddress() {
      this.showAutomplete = true;
      this.checkAddressDisabled = true;
      this.homeDeliveryAvailable = null;
      if (!this.$route.params.addressId) {
        this.internalValue = {
          addressName: null,
          doorbellName: null,
          city: null,
          addressNote: null,
          postalcode: null,
          province: null,
          addressNumber: null,
          address1: null,
          floor: null,
          elevator: null,
          referenceMobileNumber: null,
          longitude: null,
          latitude: null
        };
      }
    },
    geolocate() {
      let vm = this;
      if (navigator.geolocation) {
        document.getElementById("map").value = "";
        document.getElementById("map").placeholder =
          "Localizzazione in corso...";
        navigator.geolocation.getCurrentPosition(
          function(position) {
            var latlng = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, function(results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                vm.internalValue = GeoService.decodeGoogleAddress(results[0]);
                vm.internalValue.latitude = vm.internalValue.latitude();
                vm.internalValue.longitude = vm.internalValue.longitude();
                vm.internalValue.gpsLatitude = vm.internalValue.latitude;
                vm.internalValue.gpsLongitude = vm.internalValue.longitude;
                var addressFormat = GeoService.composeAddress(vm.internalValue);
                document.getElementById("map").value = addressFormat;
                document.getElementById("map").placeholder =
                  "Scrivi il tuo indirizzo";
                vm.checkAddress();
              } else {
                console.log(
                  "Geocode was not successful for the following reason: "
                );
              }
            });
            // var ll = new google.maps.LatLng(
            //   position.coords.latitude,
            //   position.coords.longitude
            // );
            // vm.$refs.address.updateCoordinates(ll);
          },
          function(err) {
            console.log(err);
            alert(
              "è necessario abilitare la localizzazione per utilizzare questa funzione"
            );
          },
          { enableHighAccuracy: false, maximumAge: Infinity }
        );
      }

      // this.$refs.address.geolocate();
    },
    async validateAndSaveAddress() {
      let vm = this;
      var dataValidated = await DeliveryService.isValidAddress(
        vm.internalValue
      );
      //? se non e' valido mostro snakcbar errore
      if (dataValidated.active === 0) {
        vm.error = "message.invalidAddress1";
      } else {
        if (
          !vm.internalValue.address1 ||
          !vm.internalValue.addressNumber ||
          !vm.internalValue.postalcode ||
          !vm.internalValue.city
        ) {
          vm.error = "message.invalidAddress1";
        } else {
          const text =
            "E' stato trovato questo indirizzo: <b>" +
            vm.internalValue.address1 +
            " " +
            vm.internalValue.addressNumber +
            ", " +
            vm.internalValue.postalcode +
            " " +
            vm.internalValue.city +
            "</b>. Vuoi salvarlo?";
          const dialogRes = await this.$dialog.confirm({ text });
          // eslint-disable-next-line no-unused-vars
          // _this.$emit("removeAddress", address);
          if (dialogRes) {
            vm.saveAddress();
          }
        }
      }
    },
    async saveAddress() {
      let vm = this;
      var addressUpdated = await DeliveryService.updateAddress(
        vm.internalValue
      );
      if (addressUpdated) {
        await vm.setAddress({
          addressType: "home",
          addressId: addressUpdated.addressId
        });
        vm.$emit("save");
        vm.$router.push({
          name: "Addresses"
        });
      }
    },
    back() {
      this.resetForm();
      this.$emit("back");
    }
  },
  watch: {
    address1(val, oldVal) {
      if (val && val.trim().length > 3 && val != oldVal) {
        this.fetchAddressSuggestion(val);
      }
    },
    city(val, oldVal) {
      if (val && val.trim().length > 0 && val != oldVal) {
        this.fetchCityList(val, this.provinceId);
      }
    }
  },
  async created() {
    let vm = this;
    try {
      google = await gmapsInit(global.config.google_api_key);
      if (google) {
        this.googleEnabled = true;
      }
    } catch (err) {
      console.log(err);
    }
    if (vm.$route.params.addressId) {
      this.showAutomplete = false;
      this.homeDeliveryAvailable = true;
      await vm.getAddress(vm.$route.params.addressId);
    }
    // vm.fetchDefaultData();
  },
  mounted() {
    this.resetForm();
  }
};
</script>
